"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewRequest = void 0;
const react_1 = __importStar(require("react"));
const utilities_1 = require("../../utilities");
require("./NewRequest.css");
const NewRequestInput = (props) => {
    const [request, setRequest] = (0, react_1.useState)({
        requester: {
            requester_id: props.requester_id,
            requester_name: props.requester_name,
        },
        sharer: {
            sharer_id: props.sharer.sharer_id,
            sharer_name: props.sharer.sharer_name,
        },
        title: "",
        item_id: "",
        sharer_id: "",
        start_date: "",
        end_date: "",
        sharer_points: 0,
        requester_points: 0,
        status: props.status,
    });
    const [requestSent, setRequestSent] = (0, react_1.useState)(false);
    const [itemUnavailable, setItemUnavailable] = (0, react_1.useState)(false);
    const [totalPoints, setTotalPoints] = (0, react_1.useState)(0);
    const [totalReward, setTotalReward] = (0, react_1.useState)(0);
    const calculateTotalPoints = () => {
        if (request.start_date && request.end_date) {
            const start = new Date(request.start_date);
            const end = new Date(request.end_date);
            const diffTime = Math.abs(end.getTime() - start.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays * props.points;
        }
        return 0;
    };
    (0, react_1.useEffect)(() => {
        const newTotalPoints = calculateTotalPoints();
        setTotalPoints(newTotalPoints);
        request.sharer_points = newTotalPoints;
    }, [request.start_date, request.end_date]);
    const youGetPoints = Math.ceil(props.points * 0.2);
    const calculateTotalRewards = () => {
        if (request.start_date && request.end_date) {
            const start = new Date(request.start_date);
            const end = new Date(request.end_date);
            const diffTime = Math.abs(end.getTime() - start.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays * youGetPoints;
        }
        return 0;
    };
    (0, react_1.useEffect)(() => {
        const newTotalReward = calculateTotalRewards();
        setTotalReward(newTotalReward);
        request.requester_points = newTotalReward;
    }, [request.start_date, request.end_date]);
    (0, react_1.useEffect)(() => {
        if (request.status === "pending") {
            setRequestSent(true);
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (request.status === "unavailable") {
            setItemUnavailable(true);
        }
    }, []);
    const handleDateChange = (event) => {
        setRequest(Object.assign(Object.assign({}, request), { [event.target.name]: event.target.value }));
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        // Check if start_date and end_date are not empty
        if (!request.start_date || !request.end_date) {
            alert("Start date and end date are required.");
            return;
        }
        // Check if requester is signed in
        if (!props.requester_id) {
            alert("You must be signed in to make a request.");
            return;
        }
        // Check if requester is sharer
        if (props.requester_id === request.sharer.sharer_id) {
            alert("You cannot request your own item.");
            return;
        }
        // Check if start_date is before end_date and both are in the future
        const currentDate = new Date();
        const startDate = new Date(request.start_date);
        const endDate = new Date(request.end_date);
        if (startDate < currentDate || endDate < currentDate) {
            alert("Start date and end date must be at least 24 hours from now.");
            return;
        }
        if (startDate > endDate) {
            alert("Start date must be before end date.");
            return;
        }
        props.onSubmit && props.onSubmit(request);
        setRequestSent(true);
    };
    return (react_1.default.createElement("form", { onSubmit: handleSubmit },
        react_1.default.createElement("div", { className: "date-container" },
            react_1.default.createElement("label", { className: "date-field" },
                "Start Date",
                react_1.default.createElement("input", { type: "date", name: "start_date", value: request.start_date, onChange: handleDateChange, required: true, className: "date-input", disabled: requestSent || itemUnavailable })),
            react_1.default.createElement("label", { className: "date-field" },
                "End Date",
                react_1.default.createElement("input", { type: "date", name: "end_date", value: request.end_date, onChange: handleDateChange, required: true, className: "date-input", disabled: requestSent || itemUnavailable }))),
        requestSent ? (react_1.default.createElement("p", { className: "request-sent" }, "Request Sent! Check your profile page for pending approvals.")) : itemUnavailable ? (react_1.default.createElement("p", { className: "unavailable-item-notice" }, "This item is currently shared with someone else. Check back soon!")) : (react_1.default.createElement("button", { type: "submit", className: "NewRequestInput-button u-pointer", value: "Submit" }, "Send Request")),
        react_1.default.createElement("hr", { style: {
                marginTop: "5%",
                borderWidth: "2px",
                borderRadius: "5px",
                borderStyle: "solid",
                borderColor: "#DDD8D8",
            } }),
        react_1.default.createElement("div", { className: "total-points-container" },
            react_1.default.createElement("p", { className: "total-points-text" }, "Total Points:"),
            react_1.default.createElement("p", { className: "total-points-number" },
                totalPoints,
                " Points")),
        react_1.default.createElement("div", { className: "total-rewards-container" },
            react_1.default.createElement("p", { className: "total-rewards-text" }, "Your Total Rewards:"),
            react_1.default.createElement("p", { className: "total-rewards-number" },
                totalReward,
                " Points"))));
};
const NewRequest = (props) => {
    const addRequest = (request) => {
        const body = Object.assign(Object.assign({}, request), { requester_id: props.requester.requester_id, requester_name: props.requester.requester_name, item_id: props.item_id, sharer_id: props.sharer.sharer_id, sharer_name: props.sharer.sharer_name, title: props.title });
        (0, utilities_1.post)("/api/newrequest", body).then((requestObj) => {
            (0, utilities_1.get)("/api/getproduct", { item: requestObj.item_id }).then((foundItem) => {
                const updateBody = Object.assign(Object.assign({}, foundItem), { status: "pending", request_id: requestObj._id });
                (0, utilities_1.post)("/api/updateproduct", updateBody).then((productDetails) => { });
            });
        });
    };
    return (react_1.default.createElement(NewRequestInput, { onSubmit: addRequest, requester_id: props.requester.requester_id, requester_name: props.requester.requester_name, item_id: props.item_id, sharer: { sharer_id: props.sharer.sharer_id, sharer_name: props.sharer.sharer_name }, status: props.status, points: props.points }));
};
exports.NewRequest = NewRequest;
