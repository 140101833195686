"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const router_1 = require("@reach/router");
require("./Catalog.css");
const utilities_1 = require("../../utilities");
const Catalog = () => {
    const [items, setItems] = (0, react_1.useState)([]);
    const [searchTerm, setSearchTerm] = (0, react_1.useState)("");
    const phrases = [
        "sleeping bag",
        "basketball",
        "scooter",
        "camera",
        "power strip",
        "vacuum",
        "party dress",
        "usb drive",
        "bike",
        "hair straightener",
    ];
    const [currentPhrase, setCurrentPhrase] = (0, react_1.useState)("");
    const [phraseIndex, setPhraseIndex] = (0, react_1.useState)(0);
    const [charIndex, setCharIndex] = (0, react_1.useState)(0);
    const [isDeleting, setIsDeleting] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        let isMounted = true;
        const typingSpeed = 750; // 0.75 seconds per letter
        const deletingSpeed = 750; // 0.75 seconds per letter
        const currentSpeed = isDeleting ? deletingSpeed : typingSpeed;
        // If we've finished typing or deleting a phrase, switch to the other operation
        if (!isDeleting && charIndex === phrases[phraseIndex].length) {
            setTimeout(() => isMounted && setIsDeleting(true), 3000); // Pause for 4 seconds before start deleting
        }
        else if (isDeleting && charIndex === 0) {
            isMounted && setIsDeleting(false);
            isMounted && setPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length); // Go to next phrase
        }
        // If we're not waiting to switch operations, proceed with typing or deleting
        if (!isDeleting && charIndex < phrases[phraseIndex].length) {
            isMounted &&
                setCurrentPhrase((prevPhrase) => prevPhrase + phrases[phraseIndex].charAt(charIndex));
            isMounted && setCharIndex((prevIndex) => prevIndex + 1);
        }
        else if (isDeleting && charIndex > 0) {
            isMounted && setCurrentPhrase((prevPhrase) => prevPhrase.slice(0, -1));
            isMounted && setCharIndex((prevIndex) => prevIndex - 1);
        }
        const timeoutId = setTimeout(() => {
            if (isMounted) {
                // Update the state
            }
        }, currentSpeed);
        return () => {
            isMounted = false;
            clearTimeout(timeoutId); // Clean up the timeout on unmount
        };
    }, [currentPhrase, isDeleting]);
    (0, react_1.useEffect)(() => {
        document.title = "Catalog";
        (0, utilities_1.get)("/api/catalog").then((itemsObjs) => {
            let reversedItemsObjs = itemsObjs.reverse();
            // Fetch sharer details for each item
            const itemsWithSharerDetails = reversedItemsObjs.map((item) => __awaiter(void 0, void 0, void 0, function* () {
                if (item.sharer && item.sharer.sharer_id) {
                    const userObj = yield (0, utilities_1.get)(`/api/user`, { userid: item.sharer.sharer_id });
                    return Object.assign(Object.assign({}, item), { sharerRating: userObj.rating, sharerNum: userObj.numreviews });
                }
                else {
                    return item;
                }
            }));
            Promise.all(itemsWithSharerDetails).then((items) => {
                setItems(items);
            });
        });
    }, []);
    // Search items by keyword in title
    const handleSearch = () => {
        (0, utilities_1.get)("/api/catalog").then((itemsObjs) => {
            const filteredItems = itemsObjs.filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
            let reversedItemsObjs = filteredItems.reverse();
            // setItems(filteredItems);
            // Fetch sharer details for each item
            const itemsWithSharerDetails = reversedItemsObjs.map((item) => __awaiter(void 0, void 0, void 0, function* () {
                if (item.sharer && item.sharer.sharer_id) {
                    const userObj = yield (0, utilities_1.get)(`/api/user`, { userid: item.sharer.sharer_id });
                    return Object.assign(Object.assign({}, item), { sharerRating: userObj.rating, sharerNum: userObj.numreviews });
                }
                else {
                    return item;
                }
            }));
            Promise.all(itemsWithSharerDetails).then((items) => {
                setItems(items);
            });
        });
    };
    if (!Array.isArray(items)) {
        return react_1.default.createElement("div", { className: "catalog" }, "No items to display");
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: "cat-header-search-container" },
            react_1.default.createElement("div", null,
                react_1.default.createElement("h1", { className: "cat-animated-header search-text" },
                    react_1.default.createElement("span", null, "I need a "),
                    react_1.default.createElement("span", { style: { color: "var(--third)" } }, currentPhrase))),
            react_1.default.createElement("div", { className: "cat-search-bar-container" },
                react_1.default.createElement("div", { className: "cat-search-input-container" },
                    react_1.default.createElement("input", { className: "cat-search-input", type: "text", value: searchTerm, onChange: (e) => setSearchTerm(e.target.value), onKeyDown: (e) => {
                            if (e.key === "Enter") {
                                handleSearch();
                            }
                        }, placeholder: "search..." }),
                    react_1.default.createElement("i", { className: "fas fa-search search-icon" })))),
        " ",
        react_1.default.createElement("div", { className: "catalog" }, items.map((item) => (react_1.default.createElement(router_1.Link, { to: `/item/${item.id}`, key: item.id, id: item.id, className: "item" },
            react_1.default.createElement("div", { className: "image-container" },
                react_1.default.createElement("img", { src: item.image, alt: item.title })),
            react_1.default.createElement("h4", { className: "item-text" }, item.title),
            react_1.default.createElement("div", { className: "cat-rating" },
                [...Array(5)].map((star, i) => {
                    const ratingValue = i + 1;
                    return (react_1.default.createElement("label", { key: i },
                        react_1.default.createElement("i", { className: ratingValue <= (item.sharerRating || 0)
                                ? "fas fa-star star-filled"
                                : "far fa-star star-empty" })));
                }),
                react_1.default.createElement("span", null,
                    "(",
                    item.sharerNum || 0,
                    ")")),
            react_1.default.createElement("h3", { className: "item-text" },
                item.points,
                " Points/day")))))));
};
exports.default = Catalog;
