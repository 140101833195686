"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditItem = exports.NewItem = void 0;
const react_1 = __importStar(require("react"));
const uuid_1 = require("uuid");
const utilities_1 = require("../../utilities");
const utilities_2 = require("../../utilities");
require("./NewItem.css");
const NewItemInput = ({ action, defaultValue, onSubmit }) => {
    const [item, setItem] = (0, react_1.useState)(defaultValue);
    const [isOpen, setIsOpen] = (0, react_1.useState)(false);
    const actionTextMap = {
        add: "Upload a New Share",
        edit: (react_1.default.createElement("div", null,
            react_1.default.createElement("i", { className: "fas fa-edit" }),
            " Edit")),
        delete: "Delete a Share",
    };
    const [widget, setWidget] = (0, react_1.useState)(null);
    const handleChange = (event) => {
        setItem(Object.assign(Object.assign({}, item), { [event.target.name]: event.target.value }));
    };
    const handleSubmit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        onSubmit && onSubmit(item);
        setItem({
            id: "",
            title: "",
            description: "",
            points: 0,
            minShareDays: "",
            maxShareDays: "",
            pickupLocation: "",
            returnLocation: "",
            pickupNotes: "",
            returnNotes: "",
            image: "",
        });
        setIsOpen(false);
    });
    (0, react_1.useEffect)(() => {
        const widget = window.cloudinary.createUploadWidget({
            cloudName: "dgph2xfcj",
            uploadPreset: "mhppaebs",
        }, (error, result) => {
            if (!error && result && result.event === "success") {
                // console.log("Done! Here is the image info: ", result.info);
                setItem((prevItem) => (Object.assign(Object.assign({}, prevItem), { image: result.info.url })));
            }
        });
        setWidget(widget);
    }, []);
    const openWidget = () => {
        if (widget) {
            widget.open();
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("button", { onClick: () => setIsOpen(true), className: "upload-button" }, actionTextMap[action]),
        isOpen && (react_1.default.createElement("div", { className: "modal" },
            react_1.default.createElement("div", { className: "modal-content" },
                react_1.default.createElement("span", { className: "close", onClick: () => setIsOpen(false) }, "\u00D7"),
                react_1.default.createElement("form", { onSubmit: handleSubmit },
                    react_1.default.createElement("h2", null, actionTextMap[action]),
                    react_1.default.createElement("div", { className: "form-row full-width" },
                        react_1.default.createElement("label", null,
                            "Item Title:",
                            react_1.default.createElement("p", { className: "upload-desc" }, "(Max 100 characters.)"),
                            react_1.default.createElement("input", { type: "text", name: "title", value: item.title, onChange: handleChange, maxLength: 100, className: "upload-input", required: true }))),
                    react_1.default.createElement("div", { className: "form-row full-width" },
                        react_1.default.createElement("label", null,
                            "Item Description:",
                            react_1.default.createElement("p", { className: "upload-desc" }, "Be as descriptive as possible. Include details such as size or model for applicable items (300 character max)"),
                            react_1.default.createElement("textarea", { name: "description", value: item.description, onChange: handleChange, maxLength: 300, required: true }))),
                    react_1.default.createElement("div", { className: "form-row third-width" },
                        react_1.default.createElement("label", null,
                            "Price (points/day):",
                            react_1.default.createElement("input", { type: "number", name: "points", value: item.points, onChange: handleChange, required: true })),
                        " ",
                        react_1.default.createElement("label", null,
                            "Minimum Share Days:",
                            react_1.default.createElement("input", { type: "number", name: "minShareDays", value: item.minShareDays, onChange: handleChange, required: true })),
                        react_1.default.createElement("label", null,
                            "Maximum Share Days:",
                            react_1.default.createElement("input", { type: "number", name: "maxShareDays", value: item.maxShareDays, onChange: handleChange, required: true })),
                        " "),
                    react_1.default.createElement("div", { className: "form-row half-width" },
                        react_1.default.createElement("label", null,
                            "Pickup Location:",
                            react_1.default.createElement("p", { className: "upload-desc" }, "(Max 100 characters)"),
                            react_1.default.createElement("input", { type: "text", name: "pickupLocation", value: item.pickupLocation, onChange: handleChange, className: "upload-input", maxLength: 100, required: true })),
                        " ",
                        react_1.default.createElement("label", null,
                            "Return Location:",
                            react_1.default.createElement("p", { className: "upload-desc" }, "(Max 100 characters)"),
                            react_1.default.createElement("input", { type: "text", name: "returnLocation", value: item.returnLocation, onChange: handleChange, className: "upload-input", maxLength: 100, required: true })),
                        " "),
                    react_1.default.createElement("div", { className: "form-row half-width" },
                        react_1.default.createElement("label", null,
                            "Pickup Notes:",
                            react_1.default.createElement("p", { className: "upload-desc" },
                                "More details such as time of day or setup instructions.",
                                " "),
                            react_1.default.createElement("textarea", { name: "pickupNotes", value: item.pickupNotes, onChange: handleChange, maxLength: 200, required: true })),
                        react_1.default.createElement("label", null,
                            "Return Notes:",
                            react_1.default.createElement("p", { className: "upload-desc" },
                                "More details such as time of day, product care, or return expectations.",
                                " "),
                            react_1.default.createElement("textarea", { name: "returnNotes", value: item.returnNotes, onChange: handleChange, maxLength: 200, required: true })),
                        " "),
                    react_1.default.createElement("div", { className: "form-row" },
                        react_1.default.createElement("button", { onClick: (e) => {
                                e.preventDefault();
                                openWidget();
                            }, id: "upload", className: "upload-image" }, "Upload Image"),
                        item.image && (react_1.default.createElement("img", { src: item.image, alt: "Uploaded", style: { width: "100px", height: "auto" } }))),
                    react_1.default.createElement("button", { type: "submit", id: "submit" }, "Confirm and Submit")))))));
};
const NewItem = (props) => {
    const initialItemState = {
        id: "",
        title: "",
        description: "",
        points: 0,
        minShareDays: "",
        maxShareDays: "",
        pickupLocation: "",
        returnLocation: "",
        pickupNotes: "",
        returnNotes: "",
        image: "",
    };
    const addItem = (item) => {
        const body = Object.assign(Object.assign({}, item), { id: (0, uuid_1.v4)(), sharer: {
                sharer_id: props.sharer_id,
                sharer_name: props.sharer_name,
            } });
        (0, utilities_1.post)("/api/newproduct", body).then((productDetails) => {
            window.location.reload();
        });
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(NewItemInput, { action: "add", defaultValue: initialItemState, onSubmit: addItem })));
};
exports.NewItem = NewItem;
const EditItem = ({ item_id }) => {
    const [foundItem, setFoundItem] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        (0, utilities_2.get)("/api/getproduct", { item: item_id }).then((foundItem) => {
            setFoundItem(foundItem);
        });
    }, []);
    const submitUpdate = (item) => {
        const body = Object.assign(Object.assign({}, item), { id: item_id });
        (0, utilities_1.post)("/api/updateproduct", body).then((productDetails) => {
            window.location.reload();
        });
    };
    return foundItem ? (react_1.default.createElement(NewItemInput, { action: "edit", defaultValue: foundItem, onSubmit: submitUpdate })) : null;
};
exports.EditItem = EditItem;
