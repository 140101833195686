"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewReview = void 0;
const react_1 = __importStar(require("react"));
require("./NewReview.css");
const utilities_1 = require("../../utilities");
const updateUserRating = (userid, reviews) => {
    if (reviews.length > 0) {
        const averageRating = calculateAverageRating(reviews);
        const numberOfReviews = calculateNumberOfReviews(reviews);
        (0, utilities_1.post)("/api/user", { userid, rating: averageRating, numreviews: numberOfReviews }).catch((error) => {
            console.error("Error updating user:", error);
        });
    }
};
const calculateAverageRating = (reviews) => {
    const totalRating = reviews.reduce((total, review) => total + review.rating, 0);
    const averageRating = totalRating / reviews.length;
    return Math.round(averageRating * 10) / 10;
};
const calculateNumberOfReviews = (reviews) => {
    return reviews.length;
};
const NewReviewInput = (props) => {
    const [value, setValue] = (0, react_1.useState)("");
    const [rating, setRating] = (0, react_1.useState)(""); // new state for the rating
    const handleRatingChange = (event) => {
        setRating(event.target.value);
    };
    // called whenever the user types in the new post input box
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    // called when the user hits "Submit" for a new post
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit && props.onSubmit(value, rating);
        setValue("");
        setRating("");
    };
    return (react_1.default.createElement("div", { className: "review-container" },
        react_1.default.createElement("p", { className: "review-prompt" }, "Leave a review for 5 points!"),
        react_1.default.createElement("div", { className: "rating-container" },
            react_1.default.createElement("p", null, "Rate your experience:"),
            react_1.default.createElement("select", { value: rating, onChange: handleRatingChange },
                react_1.default.createElement("option", { value: "" }),
                react_1.default.createElement("option", { value: "1" }, "1 - Poor"),
                react_1.default.createElement("option", { value: "2" }, "2 - Unsatisfied"),
                react_1.default.createElement("option", { value: "3" }, "3 - Neutral"),
                react_1.default.createElement("option", { value: "4" }, "4 - Good"),
                react_1.default.createElement("option", { value: "5" }, "5 - Excellent"))),
        react_1.default.createElement("input", { type: "text", placeholder: props.defaultText, value: value, onChange: handleChange, className: "NewPostInput-input" }),
        react_1.default.createElement("button", { type: "submit", className: "NewPostInput-button u-pointer", value: "Submit", onClick: handleSubmit }, "Submit")));
};
/**
 * New Comment is a New Post component for comments
 *
 * Proptypes
 * @param {string} defaultText is the placeholder text
 * @param {string} storyId to add comment to
 */
const NewReview = (props) => {
    const addReview = (value, rating) => {
        const body = {
            reviewer: {
                reviewer_id: props.reviewer_id,
                reviewer_name: props.reviewer_name,
            },
            sharer: {
                sharer_id: props.sharer_id,
                sharer_name: props.sharer_name,
            },
            rating: rating,
            comment: value,
        };
        (0, utilities_1.post)("/api/newreview", body).then((review) => {
            // 5 points for the reviewer for leaving a review
            (0, utilities_1.get)(`/api/user`, { userid: review.reviewer.reviewer_id }).then((userObj) => {
                const updatedObj = userObj;
                updatedObj.points += 5;
                (0, utilities_1.post)("/api/user", updatedObj);
            });
            (0, utilities_1.get)(`/api/user`, { userid: props.sharer_id }).then((userObj) => {
                (0, utilities_1.get)("/api/getreview").then((allReviews) => {
                    const userReviews = allReviews.filter((review) => {
                        if (review.sharer && review.sharer.sharer_id) {
                            return review.sharer.sharer_id === props.sharer_id;
                        }
                        else {
                            console.log("No sharer_id found for review:", review);
                            return false;
                        }
                    });
                    if (userReviews.length > 0) {
                        const averageRating = calculateAverageRating(userReviews);
                        const numberOfReviews = calculateNumberOfReviews(userReviews);
                        (0, utilities_1.post)("/api/user", Object.assign(Object.assign({}, userObj), { rating: averageRating, numreviews: numberOfReviews }));
                    }
                });
            });
        });
    };
    return react_1.default.createElement(NewReviewInput, { defaultText: `Your comment`, onSubmit: addReview });
};
exports.NewReview = NewReview;
