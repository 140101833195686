"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const utilities_1 = require("../../utilities");
const NewReview_1 = require("../modules/NewReview");
require("./Orders.css");
const date_fns_1 = require("date-fns");
const Orders = (props) => {
    const { user } = props;
    const [pendingItems, setPendingItems] = (0, react_1.useState)([]); // items that are pending approval
    const [inuseItems, setInuseItems] = (0, react_1.useState)([]); // items that are in use
    const [orderHistoryItems, setOrderHistoryItems] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        (0, utilities_1.get)("/api/pendingproduct", { user_id: user._id }).then((pendings) => {
            setPendingItems(pendings);
        });
        (0, utilities_1.get)("/api/inuseproduct", { user_id: user._id }).then((inuses) => {
            setInuseItems(inuses);
        });
        (0, utilities_1.get)("/api/orderhistoryproduct", { user_id: user._id }).then((orders) => {
            setOrderHistoryItems(orders);
        });
    }, [user]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: "orders-container" },
            react_1.default.createElement("div", null,
                react_1.default.createElement("p", { className: "orders-category" }, "Requests Pending for Approval"),
                pendingItems.length > 0 ? (pendingItems.map((item, index) => (react_1.default.createElement("div", { key: index, className: "orders-item-details" },
                    react_1.default.createElement("img", { src: item.image, alt: "ProductImange", className: "orders-product-image" }),
                    react_1.default.createElement("p", { className: "orders-item-title" }, item.title),
                    react_1.default.createElement("p", null,
                        "Shared by:",
                        " ",
                        react_1.default.createElement("span", { style: { color: "var(--primary)" } }, item.sharer.sharer_name)),
                    react_1.default.createElement("p", null,
                        "From",
                        " ",
                        react_1.default.createElement("span", { className: "orders-item-date" }, (0, date_fns_1.format)(new Date(item.start_date), "MMMM d, yyyy")),
                        " ",
                        "to",
                        " ",
                        react_1.default.createElement("span", { className: "orders-item-date" }, (0, date_fns_1.format)(new Date(item.end_date), "MMMM d, yyyy"))))))) : (react_1.default.createElement("p", null, "No items."))),
            react_1.default.createElement("div", null,
                react_1.default.createElement("p", { className: "orders-category" }, "In Use"),
                inuseItems.length > 0 ? (inuseItems.map((item, index) => (react_1.default.createElement("div", { key: index, className: "orders-item-details" },
                    react_1.default.createElement("img", { src: item.image, alt: "ProductImange", className: "orders-product-image" }),
                    react_1.default.createElement("p", { className: "orders-item-title" }, item.title),
                    react_1.default.createElement("p", null,
                        "Shared by:",
                        " ",
                        react_1.default.createElement("span", { style: { color: "var(--primary)" } }, item.sharer.sharer_name)),
                    react_1.default.createElement("p", null,
                        "From",
                        " ",
                        react_1.default.createElement("span", { className: "orders-item-date" }, (0, date_fns_1.format)(new Date(item.start_date), "MMMM d, yyyy")),
                        " ",
                        "to",
                        " ",
                        react_1.default.createElement("span", { className: "orders-item-date" }, (0, date_fns_1.format)(new Date(item.end_date), "MMMM d, yyyy"))),
                    react_1.default.createElement(NewReview_1.NewReview, { reviewer_name: item.requester.requester_name, reviewer_id: item.requester.requester_id, sharer_id: item.sharer.sharer_id, sharer_name: item.sharer.sharer_name }))))) : (react_1.default.createElement("p", null, "No items."))),
            react_1.default.createElement("div", null,
                react_1.default.createElement("p", { className: "orders-category" }, "Order History"),
                orderHistoryItems.length > 0 ? (orderHistoryItems.map((item, index) => (react_1.default.createElement("div", { key: index, className: "orders-item-details" },
                    react_1.default.createElement("img", { src: item.image, alt: "ProductImange", className: "orders-product-image" }),
                    react_1.default.createElement("p", { className: "orders-item-title" }, item.title),
                    react_1.default.createElement("p", null,
                        "Shared by:",
                        " ",
                        react_1.default.createElement("span", { style: { color: "var(--primary)" } }, item.sharer.sharer_name)),
                    react_1.default.createElement("p", null,
                        "From",
                        " ",
                        react_1.default.createElement("span", { className: "orders-item-date" }, (0, date_fns_1.format)(new Date(item.start_date), "MMMM d, yyyy")),
                        " ",
                        "to",
                        " ",
                        react_1.default.createElement("span", { className: "orders-item-date" }, (0, date_fns_1.format)(new Date(item.end_date), "MMMM d, yyyy"))),
                    react_1.default.createElement(NewReview_1.NewReview, { reviewer_name: item.requester.requester_name, reviewer_id: item.requester.requester_id, sharer_id: item.sharer.sharer_id, sharer_name: item.sharer.sharer_name }))))) : (react_1.default.createElement("p", null, "No items."))))));
};
exports.default = Orders;
