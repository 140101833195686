"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewList = void 0;
const react_1 = __importStar(require("react"));
const utilities_1 = require("../../utilities");
require("./ReviewList.css");
const displayUserReviews = (reviews) => {
    return reviews.map((review) => (react_1.default.createElement("div", { key: review._id, className: "review" },
        react_1.default.createElement("div", { className: "review-rating" },
            [...Array(5)].map((star, i) => {
                const ratingValue = i + 1;
                return (react_1.default.createElement("i", { key: i, className: ratingValue <= review.rating ? "fas fa-star star-filled" : "far fa-star star-empty", style: { fontSize: "16px" } }));
            }),
            react_1.default.createElement("span", null,
                "Rating: ",
                review.rating,
                "/5"),
            react_1.default.createElement("span", { className: "reviewer-name" },
                "Posted by ",
                review.reviewer.reviewer_name)),
        react_1.default.createElement("p", { className: "review-comment" },
            "\"",
            review.comment,
            "\""),
        react_1.default.createElement("hr", { className: "divide-line-review" }))));
};
const calculateAverageRating = (reviews) => {
    const totalRating = reviews.reduce((total, review) => total + review.rating, 0);
    const averageRating = totalRating / reviews.length;
    return Math.round(averageRating * 10) / 10;
};
const calculateNumberOfReviews = (reviews) => {
    return reviews.length;
};
const ReviewList = ({ userid }) => {
    const [reviews, setReviews] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        (0, utilities_1.get)("/api/getreview").then((allReviews) => {
            const userReviews = allReviews.filter((review) => {
                if (review.sharer && review.sharer.sharer_id) {
                    return review.sharer.sharer_id === userid;
                }
                else {
                    console.log("No sharer_id found for review:", review);
                    return false;
                }
            });
            setReviews(userReviews);
        });
    }, [userid]);
    // Replace 'userid' with the actual user id
    const userReviews = displayUserReviews(reviews);
    const averageRating = calculateAverageRating(reviews);
    const numberOfReviews = calculateNumberOfReviews(reviews);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("p", { className: "average-rating" },
            "Overall: ",
            averageRating,
            "/5"),
        react_1.default.createElement("div", { className: "rev-rating" }, [...Array(5)].map((star, i) => {
            const ratingValue = i + 1;
            return (react_1.default.createElement("label", { key: i },
                react_1.default.createElement("i", { className: ratingValue <= (averageRating || 0)
                        ? "fas fa-star star-filled"
                        : "far fa-star star-empty" })));
        })),
        react_1.default.createElement("p", { className: "number-of-reviews" },
            numberOfReviews,
            " review(s)"),
        react_1.default.createElement("hr", { className: "divide-line" }),
        userReviews));
};
exports.ReviewList = ReviewList;
