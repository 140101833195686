"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const router_1 = require("@reach/router");
const utilities_1 = require("../../utilities");
const react_scroll_1 = require("react-scroll");
require("./Home.css");
const Home = (props) => {
    const [items, setItems] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        (0, utilities_1.get)("/api/catalog").then((allItems) => {
            const availableItems = allItems.filter((item) => item.status === "available");
            const randomItems = getRandomItems(availableItems, 5);
            setItems(randomItems);
        });
    }, []);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("section", { className: "header" },
            react_1.default.createElement("h1", { className: "animated-title" }, "s h a r e d o m"),
            react_1.default.createElement("p", { className: "caption" }, "borrow anything for free"),
            react_1.default.createElement(router_1.Link, { to: "/catalog" },
                react_1.default.createElement("button", { className: "start-browsing" }, "Start Browsing")),
            " ",
            react_1.default.createElement(react_scroll_1.Link, { to: "how-it-works", smooth: true, duration: 500, id: "how-direct" },
                react_1.default.createElement("div", { className: "caption" }, "How it works"),
                react_1.default.createElement("div", null,
                    react_1.default.createElement("i", { className: "fas fa-caret-down" })))),
        react_1.default.createElement("section", { className: "how-it-works" },
            react_1.default.createElement("h2", { className: "instructions-title" }, "Discover the ultimate way to borrow anything you need for FREE with Sharedom's innovative point system. Access anything you need without purchasing while connecting with your community and creating a more sustainable way of living."),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "instructions-text" }, "Browse or search for an item you need"),
                react_1.default.createElement("div", { className: "instructions-step", id: "browse" })),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "instructions-text" },
                    "Request an item - No money needed! ",
                    react_1.default.createElement("br", null),
                    "All new users are given points to use items."),
                react_1.default.createElement("div", { className: "instructions-step", id: "making-request" })),
            " ",
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "instructions-text" }, "You can earn points by uploading items to share with others"),
                react_1.default.createElement("div", { className: "instructions-step", id: "activity" })),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "instructions-text" },
                    "GUESS WHAT: ",
                    react_1.default.createElement("br", null),
                    "ANY sharing comes with points! In other words, you can get more points to work with even from borrowing and reviewing."),
                react_1.default.createElement("div", { className: "instructions-step", id: "points" })),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "instructions-text" }, "For users: use and review"),
                react_1.default.createElement("div", { className: "instructions-step", id: "use-review" })),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "instructions-text" }, "For sharers: approve and review"),
                react_1.default.createElement("div", { className: "instructions-step", id: "approve-review" })),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "instructions-text" },
                    "Anyone can be a user, sharer, or both! ",
                    react_1.default.createElement("br", null),
                    "See your points and all activity in your profile."),
                react_1.default.createElement("div", { className: "instructions-step", id: "profile-tabs" })),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "instructions-text" }, "See what others think of each user"),
                react_1.default.createElement("div", { className: "instructions-step", id: "view-review" })),
            react_1.default.createElement("h2", { className: "instructions-ending" }, "Sign in now to get started with sharedom"))));
};
function getRandomItems(items, count) {
    // Shuffle the array
    const shuffled = items.sort(() => 0.5 - Math.random());
    // Get sub-array of first n elements after shuffled
    return shuffled.slice(0, count);
}
exports.default = Home;
